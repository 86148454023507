<template>
  <li class="nav-item d-none d-lg-block dropdown-mask">
    <b-dropdown
      variant="link"
      no-caret
      toggle-class="p-0 btn btn-flat-dark dropdown-toggle waves-effect dropdown-mask"
    >
      <template #button-content>
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Access as a researcher"
        ><i
          data-feather="user-x"
        /></span>
      </template>
      <div class="dropdown-item">
        <h6>
          Acces to the user
        </h6>
        <div>
          <v-select
            v-model="user"
            label="custom_label"
            :options="customUsers"
            :get-option-key="option => option.id"
            :placeholder="'Type 2 or more characters to search...'"
            @search="onSearch({ name: $event })"
          />
        </div>
        <div
          v-if="user"
          class="mt-1"
        >
          <div class="mb-1">
            Log in as: <strong>{{ user.name }}</strong>
          </div>
          <button
            class="btn btn-primary"
            @click="loginAsUser(user)"
          >
            Here
          </button>
          <button
            class="btn btn-secondary ms-1"
            @click="loginAsUser(user, true)"
          >
            New window
          </button>
        </div>
      </div>
      <div class="dropdown-divider" />
      <h6 class="dropdown-item-text">
        Last accesses
        <a
          v-b-toggle="`collapseAccess`"
          data-action="collapse"
        ><i
          data-feather="chevron-down"
        /></a>
      </h6>
      <b-collapse
        :id="`collapseAccess`"
      >
        <b-dropdown-item
          v-for="access in lastAccess"
          :key="access.id"
        >
          <a @click="loginAsUser(access)">{{ access.name }}</a>
          <a @click="loginAsUser(access, true)">
            <img
              width="14"
              height="14"
              :src="require('@/assets/icons/new-window.svg')"
              alt="ICREA"
              class="ms-50"
            >
          </a>
        </b-dropdown-item>
      </b-collapse>
    </b-dropdown>
  </li>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    vSelect,
    BCollapse,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      usersFake: 'auth/fakeUsers',
      lastAccess: 'users/lastAccess',
    }),
    customUsers() {
      return this.users.map(e => {
        e.custom_label = `${e.name} (${e.roles.join(', ')})`
        return e
      })
    },
  },
  async mounted() {
    if (this.lastAccess.length === 0) {
      await this.$store.dispatch('users/fetchLastAccess')
    }
  },
  methods: {
    async onSearch(search) {
      search.active = 'all'
      if (search.name !== '' && search.name.length > 1) {
        await this.$store.dispatch('users/fetchFakeUsers', search)
      }
    },
    async loginAsUser(user, mode = null) {
      await this.$store.dispatch('auth/fakeUserLogin', user.id)

      let url = null

      const fakeUser = this.usersFake.find(x => x.id === user.id)
      if (fakeUser.roles.includes('publication-admin')) {
        url = this.$router.resolve({
          name: 'researcher.publications.index',
        }).href
      } else if (fakeUser.roles.includes('researcher')) {
        url = this.$router.resolve({
          name: 'researcher.dashboard',
        }).href
      } else if (fakeUser.roles.includes('applicant')) {
        url = this.$router.resolve({
          name: 'front.senior-call',
        }).href
      } else {
        url = this.$router.currentRoute.path
      }

      if (mode) {
        window.open(`${url}?actAs=${fakeUser.id}`)
      } else {
        window.location.href = `${url}?actAs=${fakeUser.id}`
      }
    },
  },
}
</script>
